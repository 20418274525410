<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col v-if="loaded & note == null" cols="12">
        <h1 class="text-center display-4 font-weight-thin">NOTE NOT FOUND</h1>
      </v-col>
      <v-col v-if="loaded & note != null" cols="12">
        <v-textarea id="note" v-model="note" min-height="40vh" label="Note" outlined no-resize readonly />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Note',

  data() {
    return {
      note: null,
      loaded: false
    }
  },

  async mounted() {
    const note_id = this.$route.params.id
    const res = await this.$http.get('/note/view?id=' + note_id).then(res => res.data)

    this.loaded = true

    if(res.status == false)
    {
      this.note = null
      return
    }

    this.note = atob(res.message)
  }
}
</script>
