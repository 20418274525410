<template>
  <v-container fluid fill-height>
    <v-row style="height: 100%;">
      <v-col cols="12">
        <v-textarea id="note" v-model="note" min-height="40vh" label="Paste your note here" outlined no-resize />
        <v-btn @click="createNote" color="primary">New Paste</v-btn>
      </v-col>
    </v-row>

    <v-snackbar min-width="300" width="400" max-width="500" v-model="snackbar" >
      <v-text-field :value="text" readonly />

      <template v-slot:action="{ attrs }">
        <v-btn @click="copy" icon>
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false" >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data() {
      return {
        snackbar: false,
        text: null,
        note: null,
      }
    },

    methods: {
      async createNote() {
        const date_now = new Date()
        const payload = {
          note: btoa(this.note),
          timestamp: date_now.getTime()
        }

        const res = await this.$http.post('/note/create', payload).then(res => res.data)

        if(res.success != true)
          return

        this.snackbar = true
        this.text = window.location + res.note_id
      },

      async copy() {
        await navigator.clipboard.writeText(this.text)
      }
    }
  }
</script>

<style>
#note {
  height: 80vh;
}
</style>
